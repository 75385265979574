import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import { CONSTANTS } from '../../../../utils/constants';
import * as S from './styles';

const HUBSPOT_FORM_HEIGHT = '582px';

const HubSpotFormHolder = ({
	hubspotFormRegion,
	hubspotFormPortalId,
	hubspotFormFormId,
	section1Title,
	section1Subtitle,
	section1Email,
}) => {
	const [isFormReady, setIsFormReady] = useState(false);
	useEffect(() => {
		const script = document.createElement('script');
		script.src = CONSTANTS.HUBSPOT_FORM_SCRIPT_LINK;
		document.body.appendChild(script);
		const hubspotForm = document.getElementById('hubspotForm');
		hubspotForm.style.display = 'none';
		script.addEventListener('load', () => {
			if (window.hbspt) {
				window.hbspt.forms.create({
					region: hubspotFormRegion,
					portalId: hubspotFormPortalId,
					formId: hubspotFormFormId,
					target: '#hubspotForm',
					onBeforeFormInit: () => {
						setIsFormReady(true);
						hubspotForm.style.display = 'block';
					},
				});
			}
		});
	}, [hubspotFormFormId, hubspotFormPortalId, hubspotFormRegion]);

	return (
		<S.Wrapper>
			<div className="leftText">
				<h2
					className="font-black-46"
					dangerouslySetInnerHTML={{ __html: section1Title }}
				></h2>
				<p
					className="font-book-20"
					dangerouslySetInnerHTML={{ __html: section1Subtitle }}
				></p>
				<p className="font-bold-26">{section1Email}</p>
			</div>
			<S.HubspotFormWrapper height={HUBSPOT_FORM_HEIGHT}>
				<div id="hubspotForm"></div>
				{!isFormReady && <Loader />}
			</S.HubspotFormWrapper>
		</S.Wrapper>
	);
};

export default HubSpotFormHolder;
