import styled from 'styled-components';
import {
	setColors,
	mediaQuery,
	setFontSize,
	setFontFamily,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.header`
	width: 100%;
	text-align: center;
	h1 {
		color: ${setColors.lightBlack};
		padding: 100px 0;
		margin: 0;
		font-family: ${setFontFamily.black};
		font-size: ${setFontSize.larger};
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		font-weight: ${setFontWeight.normal};
	}
	${mediaQuery.laptopXL`
		h1{
			font-size: ${setFontSize.mediumLarge};
		}
	`}
	${mediaQuery.tabletL`
		h1{
			font-size: ${setFontSize.medium};
		}
	`}
	${mediaQuery.mobileXL`
		h1{
			padding: ${props => (props.privacy ? '100px 0' : '80px 0')};
			font-size: ${setFontSize.large};
		}
	`}
`;
