import styled from 'styled-components';
import {
	media,
	setFontFamily,
	setFontSize,
	setColors,
} from '../../../../utils/helpers';

export const Wrapper = styled.section`
	display: grid;
	grid-template-columns: 1fr;
	padding: 50px 0;
	width: 100%;

	div {
		display: flex;
		justify-content: flex-start;
		span {
			margin: 0 4%;
		}
		svg {
			width: 20px;
			height: 20px;
			margin-top: 2%;
		}
		h3 {
			margin: 0rem;
			font-family: ${setFontFamily.bold};
			font-size: ${setFontSize.mediumTiny};
		}

		p {
			margin: 0;
			line-height: normal !important;
			color: ${setColors.lightBlack};
			max-width: 200px;
			margin: 0 auto 0 0;
		}

		a,
		.phone {
			text-decoration: none;
			color: ${setColors.slateBlack};
		}
	}
	.address-div,
	.phone-div,
	.email-div {
		padding: 15px 0;
	}
	.address-div {
		padding-top: 0;
	}
	.email-div {
		padding-bottom: 0;
	}

	${media.tablet`
		grid-template-columns:1fr 1fr 1fr;
		justify-content: space-around;
		padding: 40px 0;
		.address-div,
		.phone-div,
		.email-div {
			padding: 0;
			padding-left: 15%;
		}
		p{
			margin: 0;
		}
		div{
			justify-content: flex-start;
			margin-bottom: 0;
			svg {
				width: 24px;
				height: 24px;
				margin-top: 0;
			}
		}
		h3{
			font-size: ${setFontSize.veryTiny};
		}
		
	`};

	${media.desktop`
		padding: 60px 0;
		div{
			padding-left: 40px;
			span{
				margin: 0 2%;
			}
			svg{
				margin-top: 2%;
			}
		}
		h3{
			font-size: ${setFontSize.mediumTiny};
		}
	`}
	@media(min-width: 1150px) {
		div {
			span {
				margin: 0 3%;
			}
		}
	}
	@media (min-width: 1300px) {
		div {
			span {
				margin: 0 4%;
			}
		}
	}
	${media.large`
		div {
			span {
				margin: 0 3%;
			}
		}
	`}
`;
