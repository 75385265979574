import React from 'react';
import { graphql } from 'gatsby';
import HubSpotFormHolder from '../components/global/form-holder/HubSpotFormHolder';
import Header from '../components/global/header/Header';
import Layout from '../components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import CTA from '../components/global/CTA/CTA';
import AddressDetails from '../components/global/address-details/AddressDetails';
import SEO from '../components/global/seo';
import Padding from '../components/global/Padding/Padding';
import { checkIfProduction } from '../../utils/helpers';
import * as S from '../components/contact-page/styles';
import '../fonts/typography.css';

const contact = ({ data }) => {
	const {
		section1Email,
		section1Subtitle,
		section1Title,
		section2AddressLine1,
		section2Phone,
		section3Subtitle,
		section3Title,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
	} = data.allContentfulContactPage.nodes[0];
	const [
		hubspotFormRegion,
		hubspotFormPortalId,
		hubspotFormFormId,
	] = checkIfProduction()
		? [
				process.env.GATSBY_PROD_HUBSPOT_FORM_REGION,
				process.env.GATSBY_PROD_HUBSPOT_FORM_PORTALID,
				process.env.GATSBY_PROD_HUBSPOT_FORM_FORMID,
		  ]
		: [
				process.env.GATSBY_DEV_HUBSPOT_FORM_REGION,
				process.env.GATSBY_DEV_HUBSPOT_FORM_PORTALID,
				process.env.GATSBY_DEV_HUBSPOT_FORM_FORMID,
		  ];
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				article={seoArticle}
				image={seoImage}
				seoJsonSchema={seoJsonSchema}
			/>
			<Header title="Contact Us" />
			<WrapperOne disableBottomMargin="true">
				<S.Wrapper>
					<HubSpotFormHolder
						hubspotFormRegion={hubspotFormRegion}
						hubspotFormPortalId={hubspotFormPortalId}
						hubspotFormFormId={hubspotFormFormId}
						section1Email={section1Email}
						section1Subtitle={section1Subtitle}
						section1Title={section1Title}
					/>
					<AddressDetails
						section1Email={section1Email}
						section2AddressLine1={section2AddressLine1}
						section2Phone={section2Phone}
					/>
				</S.Wrapper>
				<Padding padding="70" />
				<S.CTAWrapper>
					<CTA title={section3Title} subtitle={section3Subtitle} />
				</S.CTAWrapper>
			</WrapperOne>
		</Layout>
	);
};

export const query = graphql`
	{
		allContentfulContactPage {
			nodes {
				section1Email
				section1Subtitle
				section1Title
				section2AddressLine1
				section2Phone
				section3Subtitle
				section3Title
				seoTitle
				seoDescription
				seoArticle
				seoImage {
					title
					file {
						url
						details {
							image {
								height
								width
							}
						}
					}
				}
				seoJsonSchema {
					internal {
						content
					}
				}
			}
		}
	}
`;

export default contact;
