import styled from 'styled-components';
import { setColors } from '../../../../utils/helpers';

export const LoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const Loader = styled.div`
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 2px solid ${setColors.white};
	border-radius: 50%;
	border-top-color: ${setColors.themeBlue};
	animation: spin 0.5s linear infinite;
	-webkit-animation: spin 0.5s linear infinite;
	left: calc(50% - 15px);
	top: calc(50% - 15px);
	z-index: 1;
	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		to {
			-webkit-transform: rotate(360deg);
		}
	}
`;
