import React from 'react';
import * as S from './styles.js';

const Loader = () => {
	return (
		<S.LoaderWrapper>
			<S.Loader />
		</S.LoaderWrapper>
	);
};

export default Loader;
