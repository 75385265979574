import React from 'react';
import * as S from './styles';
import '../../../fonts/typography.css'
const Header = ({ title,privacy }) => {
	return (
		<S.Wrapper privacy={privacy}>
			<h1 className={privacy?'':`font-black-54`}>{title}</h1>
		</S.Wrapper>
	);
};

export default Header;
