import React from 'react';
import * as S from './styles';
import Location from '../../../svg/location.inline.svg';
import Email from '../../../svg/email.inline.svg';
import Phone from '../../../svg/phone.inline.svg';
import '../../../fonts/typography.css';

const AddressDetails = ({
	section1Email,
	section2AddressLine1,
	section2Phone,
}) => {
	return (
		<S.Wrapper>
			<div className="address-div">
				<Location />
				<span>
					<h3 className="font-bold-18">Address</h3>
					<p className="font-book-18">{section2AddressLine1} </p>
				</span>
			</div>
			<div className="phone-div">
				<Phone />
				<span>
					<h3 className="font-bold-18">Phone</h3>
					<p className="phone font-book-18">
						<a href={`tel:${section2Phone}`}>{section2Phone}</a>
					</p>
				</span>
			</div>
			<div className="email-div">
				<Email />
				<span>
					<h3 className="font-bold-18">Email</h3>
					<p className="font-book-18">
						<a href={`mailto:${section1Email}`}>{section1Email}</a>
					</p>
				</span>
			</div>
		</S.Wrapper>
	);
};

export default AddressDetails;
